import React from 'react'
import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { useState } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
const Pricing = () => {
    const [ checked, setChecked ] = useState(false)
    const toggleChecked = () => {
        setChecked(!checked)
    }
    return (
        <div>
            <Layout>
                <Seo title="Pricing" description="Pricing page Ricta Browser" /> 
                <div className="p-4 lg:px-12">
                    <h1 className="font-semibold text-textColor text-5xl leading-loose ">
                Ricta pricing plan
                    </h1>
                    <div>
                        <div className="flex">
                            <label className={`mt-2 mr-4 text-lg ${checked ? 'text-disabledColor' : 'text-textColor'}`}>Monthly </label>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={checked} onChange={toggleChecked} color="primary" />}
                                />
                            </FormGroup>
                            <label className={`mt-2 text-lg ${checked ? 'text-textColor' : 'text-disabledColor'}`}>Yearly (two months free) </label>
                        </div>
                        <div className="mt-4 grid md:grid-cols-3 w-full grid-cols-1 lg:grid-cols-4 text-textColor shadow-lg rounded-lg">
                            <div className="bg-white hidden lg:block overflow-hidden hover:bg-gray-100 rounded-tl-lg rounded-bl-lg border border-gray-200">
                                <div className="text-justify">
                                    <div className="font-bold text-xl border-b-2 p-2 text-center bg-mainColor text-white rounded-tl-lg">License Name </div>
                                    <div className="grid grid-rows-3">
                                        <div className="font-bold text-2xl text-mainColor p-2">{checked ?'Yearly charge' : 'Monthly charge'}</div>
                                        <div className="border-b-2 p-1">License Type</div>
                                        <div className="font-semibold p-1 bg-mainColor text-white">License cost (per user)</div>
                                        <div className=" p-1">Support Ticket Response Time</div>
                                        <div className="p-1">Perpetual</div>
                                        <div className="p-1">Yearly updates</div>
                                        <div className="border-b-2 p-1">Lifetime</div>
                                        <div className=" font-semibold p-1 bg-mainColor text-white">Limits</div>
                                        <div className=" p-1">Users</div>
                                        <div className=" p-1">Concurrent logins</div>
                                        <div className="p-1">Max Devices</div>
                                        <div className="border-b-2 p-1">Free Trial</div>
                                        <div className=" font-semibold p-1 bg-mainColor text-white">Feautres</div>
                                        <div className=" p-1">Work spaces</div>
                                        <div className=" p-1">Screens</div>
                                        <div className=" p-1">White label</div>
                                        <div className=" p-1">Team Offer</div>
                                        <div className=" p-1">Pssword Manager</div>
                                        <div className=" p-1">Mobile view;Custom View</div>
                                        <div className=" p-1">Encrypt Cloud Sync</div>
                                        <div className="lg:text-sm xl:text-base p-1">Manual Backup/Restore Function</div>
                                        <div className=" p-1">Notification Setup</div>
                                        <div className=" p-1">Hibernation</div>
                                        <div className=" p-1">Javascript injection launch time</div>
                                        <div className=" p-1">Bookmarklet</div>
                                        <div className=" p-1">Spellcheck</div>
                                        <div className=" p-1">Proxies</div>
                                        <div className=" p-1">Toolbox function</div>
                                        <div className=" p-1">Light/Dark mode</div>
                                        <div className=" p-1">Windows;Linux;Mac</div>
                                        <div className=" p-1">Resource utilization monitoring</div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white overflow-hidden hover:bg-gray-100 border border-gray-200">
                                <div className="text-center">
                                    <div className="font-bold text-xl border-b-2 p-2 text-center bg-mainColor text-white">Commmunity Edition </div>
                                    <div className="grid grid-rows-3 text-center">
                                        <div className="font-bold text-2xl text-mainColor p-2"><span className="sm:block font-medium text-base lg:hidden">{checked ?'Yearly charge' : 'Monthly charge'}<br/></span>Free</div>
                                        <div className="border-b-2 p-1"><span className="sm:block font-medium text-base lg:hidden">License Type<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/> </div>
                                        <div className="p-1 bg-mainColor text-white lg:text-mainColor">License Cost (per User)</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Support Ticket Response Time <br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Perpetual<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Yearly updates<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1 border-b-2"><span className="sm:block font-medium text-base lg:hidden">Lifetime<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1 bg-mainColor text-white lg:text-mainColor">Limits</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Users<br/></span>1</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Concurrent Logins<br/></span>1</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Max Devices<br/></span>1</div>
                                        <div className="p-1 border-b-2"><span className="sm:block font-medium text-base lg:hidden">Free Trial<br/></span>unlimited</div>
                                        <div className="p-1 bg-mainColor text-white lg:text-mainColor">Features</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Work spaces<br/></span>2</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Screens<br/></span>1</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">White Label<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Team Offer<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Password Manager<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Mobile View;Custom View<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Encrypt Cloud Sync<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Manual Backup&Restore Function<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Notification setup<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Hibernation<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Javascript injection time<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Bookmarklet<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">SpellCheck<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Proxies<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Toolbox Function<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Light/Dark mode<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Windows;Linux;Mac<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Resource utilization monitor<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>                             
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white overflow-hidden hover:bg-gray-100 border border-gray-200">
                                <div className="text-center">
                                    <div className="font-bold text-xl border-b-2 p-2 text-center bg-mainColor text-white">Basic </div>
                                    <div className="grid grid-rows-3 text-center">
                                        <div className="font-bold text-2xl text-mainColor p-2"><span className="sm:block font-medium text-base lg:hidden">{checked ? 'Yearly charge': 'Monthly charge'}<br/></span>{checked ? 
                                            <> 
                                    $49 <span>/yr<br/></span>
                                            </>
                                            : <> 
                                    $4.90<span>/mo<br/></span>
                                            </>}</div>
                                        <div className="border-b-2 p-1"> <span className="sm:block font-medium text-base lg:hidden">License Type<br/></span>12 month subscription </div>
                                        <div className="p-1 bg-mainColor text-white lg:text-mainColor">License Cost (per User)</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Support Ticket Response Time<br/></span>72h</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Perpetual<br/></span>72h</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Yearly updates<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1 border-b-2"><span className="sm:block font-medium text-base lg:hidden">Lifetime<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1 bg-mainColor text-white lg:text-mainColor">Limits</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Users<br/></span>1</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Concurrent Logins<br/></span>1</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Max Devices<br/></span>unlimited</div>
                                        <div className="p-1 border-b-2"><span className="sm:block font-medium text-base lg:hidden">Free Trial<br/></span>14 days</div>
                                        <div className="p-1 bg-mainColor text-white lg:text-mainColor">Features</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Work spaces<br/></span>3</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Screens<br/></span>10</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">White Label<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Team Offer<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Password Manager<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Mobile View;Custom View<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Encrypt Cloud Sync<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Manual Backup&Restore Function<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Notification setup<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Hibernation<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Javascript injection time<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Bookmarklet<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">SpellCheck<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Proxies<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Toolbox Function<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Light/Dark mode<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Windows;Linux;Mac<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Resource utilization monitor<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white overflow-hidden hover:bg-gray-100 border border-gray-200 rounded-tr-lg rounded-br-lg">
                                <div className="text-center">
                                    <div className="font-bold text-xl border-b-2 p-2 text-center bg-mainColor text-white rounded-tr-lg">Pro </div>
                                    <div className="grid grid-rows-3 text-center">
                                        <div className="font-bold text-2xl text-mainColor p-2"><span className="sm:block font-medium text-base lg:hidden">{checked ? 'Yearly charge': 'Monthly charge'}<br/></span>{checked ? 
                                            <> 
                                    $99 <span>/yr<br/></span>
                                            </>
                                            : <> 
                                    $9.90<span>/mo<br/></span>
                                            </>}</div>
                                        <div className="border-b-2 p-1"> <span className="sm:block font-medium text-base lg:hidden">License Type<br/></span>12 month subscription </div>
                                        <div className="p-1 bg-mainColor text-white lg:text-mainColor">License Cost(per User)</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Support Ticket Response Time<br/></span>48h</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Perpetual<br/></span>48h</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Yearly updates<br/></span>$49.90</div>
                                        <div className="p-1 border-b-2"><span className="sm:block font-medium text-base lg:hidden">Lifetime<br/></span>$499</div>
                                        <div className="p-1 bg-mainColor text-white lg:text-mainColor">Limits</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Users<br/></span>1</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Concurrent Logins<br/></span>1</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Max Devices<br/></span>unlimited</div>
                                        <div className="p-1 border-b-2"><span className="sm:block font-medium text-base lg:hidden">Free Trial<br/></span>14 days</div>
                                        <div className="p-1 bg-mainColor text-white lg:text-mainColor">Features</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Work spaces<br/></span>unlimited</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Screens<br/></span>unlimited</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">White Label<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1 text-sm"><span className="sm:block font-medium text-base lg:hidden">Team Offer<br/></span>-10% discount on the yearly offer</div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Password Manager<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Mobile View;Custom View<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Encrypt Cloud Sync<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Manual Backup Restore Function<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Notification setup<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Hibernation<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Javascript injection time<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Bookmarklet<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">SpellCheck<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Proxies<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Toolbox Function<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Light/Dark mode<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Windows;Linux;Mac<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                        <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Resource utilization monitor<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )

}
export default Pricing